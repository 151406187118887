import React, { useState } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import RemoveHTML from "../../components/removeHtml"
import Layout from "../../components/layout"
import Seo from "./../../components/seo"
import { Tabbordion, TabPanel, TabLabel, TabContent } from "react-tabbordion"
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import useLiveControl from "./../../controlled/useLiveControl"
import LiveControlSpinner from "../../components/liveControlSpinner"

// accordian
const blockElements = {
    animator: "accordion-animator",
    content: "accordion-content",
    panel: "accordion-panel",
    label: "accordion-title",
  }

  /**
 * @type {React.ExoticComponent<import('@szhsin/react-accordion').AccordionItemProps>}
 */

  const AccordionItem = ({ header, ...rest }) => (
    <Item
      {...rest}
      header={
        <>
          {header}
          <span className="accordion-icon"></span>
        </>
      }
    />
  );

const RegionsHomeownerPage = ( ) => {

    const { isStaging, runningChecks } = useLiveControl()

  if (runningChecks || !isStaging) return <LiveControlSpinner />

    return (
    <Layout>
        <Seo
        title={"About Regions Home Improvement Financing"}
        description={"As of the date of the merger, Regions is a party to EnerBank customer forms, disclosures, documents, and agreements that name EnerBank as a party, and those forms, disclosures, documents, and agreements should be read with Regions substituted for EnerBank."}
        />
        <Helmet>
        <body id="rebrand" className="page-regions-contractor" />
        </Helmet>
        <section className="banner-area" id="regions-page-banner-section" >
             <div className="container banner-container"
                style={{
                    backgroundImage:
                    "url(" +
                    "http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/regions-homeowner-page-hero.jpg" +
                    ")",
                }}
            >
        <div className="relative banner-area-text-container">
          <div className="container text-center">
            <div class="identifier-container row-fluid">
              <div class="element span12">
                  <div class="rhif-identifier span5">
                      <h4>HOME IMPROVEMENT FINANCING</h4>
                  </div>
                  <div class="right-angle span2"></div>
              </div>
            </div>
            <h1 className="text-white text-left desktop" style={{ padding: "80 0" }}> 
            Same great products and services, <strong>new name</strong>
            </h1>
          </div>
        </div>
      </div>
      <div className="container text-center mobile">
        <h1 className="text-white text-left" style={{ padding: "80 0" }}>
            Same great products and services, <strong>new name</strong>
        </h1>
      </div>
      </section>
      <section className="paragraph-text-block">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <p>For over two decades, EnerBank USA has been helping contractors offer financing to their home improvement customers — getting them the funds they needs for HVAC, roofing, siding, painting, window+door, kitchen and bath remodel, outdoor living, and more. </p>
            </div>
          </div>
        </div>
      </section>
      <section className="stronger-future bg-teal">
        <div className="bg-teal">
            <h2 className="text-center text-white mb-4">Home improvement lending with Regions works for you</h2>
        </div>
        <div className="container">
            <div className="text-center text-white">
            <p className="text-white">Our fast, flexible financing for home improvement projects makes it easier to manage your money the way you want while getting the repairs or upgrades you need for your home.</p>
            <p>Regions is one of the nation&#39;s leading providers of commercial, consumer, personal banking, and homebuilder finance services, operating approximately 1,300 banking offices and 2,000 ATMs across the South, Midwest, and Texas.</p>
            </div>
          </div>
        </section>
        <section className="spotlight-offset">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/broad-range-of-financial-products.jpg" className="image" alt="a couple using a laptop"
                    />
                    </div>
                    <div className="col-lg-7 spotlight-band-copy">
                        <h2>A wider variety of lending options</h2>
                        <p>Regions helps you make the best choices for your money goals. We offer more than just unsecured loans, opening up the possibilities for greater financial freedom.</p>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/dedicated-personalized-support.jpg" className="image" alt="a couple using a laptop"
                    />
                    </div>
                    <div className="col-lg-7 spotlight-band-copy">
                        <h2>A dedicated team for personalized support</h2>
                        <p>Regions ranked #1 in online banking and customer satisfaction by JD Power, making a positive difference in for you and your customers. Check out our <Link classname="eb-link" to="https://www.regions.com/promo/awards">awards</Link>.</p>
                        <p>Learn more about Regions <Link classname="eb-link" to="https://www.regions.com">here</Link>.</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="qa-accordion bg-light-gray">
            <div className="container">
                <div className="col-md-12 mb-5">
                    <h2 className="text-center mb-4">Q & A for homeowners</h2>
                    <p>As we complete our transition to Regions Home Improvement Financing, here are a few questions and answers to help you better understand our brand update</p>
                </div>
            <div className="row">
                <div className="col-md-12">
                <Accordion transition transitionTimeout={250}>
                    <AccordionItem header="What&#39;s in it for me?">
                    <p>Plenty of good things! With EnerBank&#39;s proven platform and skilled financial professionals, Regions Home Improvement Financing is ideally positioned to offer you convenient home improvement lending options.</p>
                    </AccordionItem>
                    <AccordionItem header="Will I need to do anything differently?">
                        <p>If there are any changes to how you access information about your loan, or make payments, we&#39;ll be sure to let you know with plenty of notice.</p>
                    </AccordionItem>
                    <AccordionItem header="Will the terms of my loan change?">
                        <p>No, your loan will keep the same interest rate, duration, and payments.</p>
                    </AccordionItem>
                    <AccordionItem header="What are some of Regions&#39; other financial products?">
                        <p>The full range of Regions&#39; services is currently available in  <Link classname="eb-link" to="https://www.regions.com/locator">stop by a convenient branch in our 15-state footprint</Link>.</p>
                    </AccordionItem>
                    <AccordionItem header="If I need additional information about Regions, who can I contact?">
                        <p>Please visit <Link classname="eb-link" to="https://www.regions.com">regions.com</Link> or <Link classname="eb-link" to="https://www.regions.com/locator">stop by a convenient branch in our 15-state footprint</Link>.</p>
                    </AccordionItem>
                    <AccordionItem header="I've got more questions. Who can I talk to?">
                        <p>Please contact your relationship manager or call{" "}
                                    <a href="tel:888.390.1220">888.390.1220</a>, option 1</p>
                    </AccordionItem>
                    <AccordionItem header="How can I make a payment now that you&#39;re Regions home improvement financing?" initialEntered>
                        <p>If you&#39;re already making payments, there&#39;s no need to do anything differently. These are the six convenient ways to make a payment.</p>
                        <Accordion transition transitionTimeout={250}>
                            <AccordionItem header="Make payments online:" initialEntered>
                                <ul>
                                    <li>If your account number starts with 456, <Link classname="eb-link" to="https://account.enerbank.com./">log in to your Regions | EnerBank USA account</Link>. <br/>
                                    If your account number begins with numbers other than 456, <Link classname="eb-link" to="https://payments.enerbank.com/">log in to your Regions | EnerBank USA account here</Link>.</li>
                                    <li>There&#39;s no charge to schedule one-time or recurring payments from a debit card or personal checking or savings account.</li>
                                    <li>Note that Regions Bank accepts payments for loans using a debit card, but we do not accept credit cards. </li>
                                    <li><Link classname="eb-link" to="https://enerbank.com/text-to-pay-feature/">Learn more about text-to-pay</Link>.</li>
                                </ul>
                            </AccordionItem>

                            <AccordionItem header="Schedule recurring payments at no charge: ">
                                <ul>
                                    <li>Please contact your relationship manager or call{" "} <a href="tel:888.390.1220">888.390.1220</a>, option 1 or</li>
                                    <li>Complete the <Link classname="eb-link" to="https://enerbank.com/static/automatic-debt-authorization-form-5eb34b7350b5addf0bb182521fdde02f.pdf">automatic debit form</Link> and return it to: <br/>
                                    <strong>Regions|EnerBank USA <br/>
                                    650 S Main St, Suite 1000<br/>
                                    Salt Lake City, UT 84101</strong> </li>
                                    <li>Note that Regions Bank accepts payments for loans using a debit card, but we do not accept credit cards. </li>
                                </ul>
                            </AccordionItem>
                            <AccordionItem header="Make an online bill payment through your financial institution:">
                                <p>Set up Regions Bank as a new payee with your financial institution&#39;s online bill payment service. </p>
                                <p>Set up a payment with the payment amount due to Regions Bank. </p>
                            </AccordionItem>
                            <AccordionItem header="Mail your payment with a payment coupon:">
                                <ul>
                                    <li>If your account number starts with 456:  <br/>
                                    <strong>Regions|EnerBank USA <br/>
                                    PO Box 30122 <br/>
                                    Salt Lake City, UT 84130-0122 </strong> </li>
                                    <li>If your account number begins with numbers other than 456: <br/>
                                    <strong>Regions|EnerBank USA <br/>
                                    PO Box 26856 <br/>
                                    Salt Lake City, UT 84126-0856 </strong> </li>
                                </ul>
                            </AccordionItem>
                            <AccordionItem header="Send a payment via overnight delivery: ">
                                <p><strong>Regions | EnerBank USA<br/>
                                650 S Main St, Suite 1000 <br/>
                                Salt Lake City, UT 84101 </strong></p>
                            </AccordionItem>
                            <AccordionItem header="Make a payment over the phone: ">
                                <ul>
                                    <li>Call Customer Service at{" "} <a href="tel:888.390.1220">888.390.1220</a>, and choose option 1 </li>
                                    <li>There&#39;s no charge to schedule one-time or recurring payments from a debit card or personal checking or savings account. </li>
                                    <li>Note that Regions Bank accepts payments for loans using a debit card, but we do not accept credit cards. </li>
                                </ul>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>
                </div>
            </div>
            </div>
      </section>
    </Layout>
    )
}
export default RegionsHomeownerPage
